import postData from '../assets/js/fetch.js';

let dev;
process.env.NODE_ENV === 'production' ? dev = "" : dev = "https://xapp.icnea.net";

const state = {
    cleanings: [],
    cleaningsCalendar: [],
    cleaningDetail: [],
    numIncidencia: ''
};

const mutations = {
    setCleanings(state, cleanings) {
        state.cleanings = cleanings;
    },
    setCleaningsCalendar(state, cleaningsCalendar) {
        state.cleaningsCalendar = cleaningsCalendar;
    },
    setCleaningDetail(state, cleaningDetail) {
        state.cleaningDetail = cleaningDetail;
    },
    setNumIncidencia(state, numIncidencia) {
        state.numIncidencia = numIncidencia;
    }
};

const actions = {
    //// Cleaning ////
    getCleanings({ commit }, { usr, data }) {
        postData(dev + "/WebService.asmx/Netejes", { usr, data })
        .then((data) => {
            let array = [];
            JSON.parse(data.d).Response.dates.forEach(neteja => {
                neteja.expanded = true;
                array.push(neteja);
            });

            const neteges = { dates: array };
            console.log('Netejes', neteges);
            commit("setCleanings", neteges);
        });
    },
    getCleaningsCalendar({ commit }, { usr, data }) {
        postData(dev + "/WebService.asmx/NetejesCalendari", { usr, data })
        .then((data) => {
            console.log('NetejesCalendari', JSON.parse(data.d).Response);
            commit("setCleaningsCalendar", JSON.parse(data.d).Response);
        });
    },
    getCleaningDetail({ commit }, { usr, id, data }) {
        postData(dev + "/WebService.asmx/NetejesDetall", { usr, id, data })
        .then((data) => {
          
            console.log('NetejesDetall', JSON.parse(data.d).Response);
            commit("setCleaningDetail", JSON.parse(data.d).Response);
        });
    },
    postCleaningStatus({ dispatch }, { usr, id, final, fecha, lat, lng }) {
        console.log(usr, id, final, fecha)
        postData(dev + "/WebService.asmx/NetejesEstat", { usr, id, final, lat, lng })
        .then((data) => {
            if (data.d == true) {
                dispatch("getCleaningDetail", { usr, id, data: fecha });        
            }
        });
    },
    postIncidencia({ commit }, { usr, lodging_id, titular, texte, motiu }) {
        console.log(usr, lodging_id, titular, texte, motiu)
        postData(dev + "/WebService.asmx/ServeiCrear", { usr, lodgingId: lodging_id, titular, texte, motiu })
        .then((data) => {
            commit("setNumIncidencia", data.d);
        });
    },
    postNetejaEnviarFotos({ dispatch }, { usr, id, data, image1, image2, image3, idUrl }) {
        const image4 = '', image5 = '', image6 = '';
        if (image1 == '' &&  image2 == '' && image3 == '') {
            dispatch("getCleaningDetail", { usr, id: idUrl, data });
        } else {
            postData(dev + "/WebService.asmx/ServeiImatge", { usr, id, image1, image2, image3, image4, image5, image6, data })
            .then((datos) => {
                console.log('rawServeiImatge', datos)
                if (datos.d === true) {
                    dispatch("getCleaningDetail", { usr, id: idUrl, data });
                }
            });
        }
    },
    postNetejesChecklistConfirmar({ dispatch }, { usr, cleaning_id, cleaning_instruction_id, data, lat, lng } ) {
        postData(dev + "/WebService.asmx/NetejesChecklistConfirmar", { usr, cleaning_id, cleaning_instruction_id, lat, lng })
            .then((datos) => {
                if (datos.d === true) {
                    dispatch("getCleaningDetail", { usr, id: cleaning_id, data });
                }
            });
    }, 
    postNetejesChecklistFoto({ dispatch }, { usr, cleaning_id, cleaning_instruction_id, base64, data, lat, lng } ) {
        postData(dev + "/WebService.asmx/NetejesChecklistFoto", { usr, cleaning_id, cleaning_instruction_id, base64, lat, lng })
            .then((datos) => {
                if (datos.d === true) {
                    dispatch("getCleaningDetail", { usr, id: cleaning_id, data });
                }
            });
    },
    postNetejesObservacions({ dispatch }, { usr, lodging_id,  cleaning_service_remarks, id, datap }) {
        postData(dev + "/WebService.asmx/NetejesObservacions", { usr, lodging_id, cleaning_service_remarks })
        .then((data) => {
            console.log('NetejesObservacions', data.d)
            if (data.d === true) {
                console.log(usr, id, datap)
                dispatch("getCleaningDetail", { usr, id, data: datap });
            }
        });
    }, 
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};